import { dateString, timeAgoString } from "../../app/dateHelpers";
import { NoteModel } from "../../data/entities/NoteModel";
import { InspectionImages, Measurement } from "../../components";
import InspectionNoteDescription from "./InspectionNoteDescription";
import InspectionNoteValues from "./InspectionNoteValues";
import { MeasurementUnit } from "../../data/entities";
import { useEffect, useMemo, useState } from "react";
import { UserService } from "../../services";
import { MeasurementConverter } from "../../app/measurementConverter";

interface InspectionNoteProps {
  note: NoteModel
}

export default function InspectionNote(props: InspectionNoteProps) {
  const { note } = props;
  const [startPosition, setStartPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(0);

  const converterService = useMemo(() => new MeasurementConverter(), []);
  const preferredMeasurement = UserService.getPreferredMeasurement();
  const systemMeasurement = converterService.getDefaultConverter().system;

  useEffect(() => {
    // convert the notes start and end positions to preferred measurement.
    // The props value should always be in meters
    const convertedStartPosition = !note.startPosition ? null :
      converterService.convert({
        value: note.startPosition,
        system: systemMeasurement,
        unit: MeasurementUnit.distanceLarge
      }, preferredMeasurement);
    const convertedEndPosition = !note.endPosition ? null :
      converterService.convert({
        value: note.endPosition,
        system: systemMeasurement,
        unit: MeasurementUnit.distanceLarge
      }, preferredMeasurement);
      
    setStartPosition(convertedStartPosition?.value ?? 0);
    setEndPosition(convertedEndPosition?.value ?? convertedStartPosition?.value ?? 0);
  }, [converterService, note.endPosition, note.startPosition, preferredMeasurement, systemMeasurement]);

  return (
    <>
      <div className="row" id={note.id?.toString()}>
        <div className="col">
          <div className="note-author">
            <h6 className="mb-0">{note.user?.displayName}</h6>
          </div>
          <div className="note-date small">
            <span>{dateString(new Date(note.createdDate))}</span>
            <span className="d-none d-md-inline">
              {" "}
              ({timeAgoString(new Date(note.createdDate))})
            </span>
          </div>
          <div className="note-position small">
            <span>
              <b>Distance from core:</b>&nbsp;
              {
                endPosition === startPosition ? 
                <>
                  <Measurement
                    value={startPosition}
                    unit={MeasurementUnit.distanceLarge}
                  />
                </> : (
                <>
                  <Measurement
                    value={startPosition}
                    unit={MeasurementUnit.distanceLarge}
                  />
                  <span> - </span>
                  <Measurement
                    value={endPosition!}
                    unit={MeasurementUnit.distanceLarge}
                  />
                </>
              )
              }
            </span>
          </div>
          <div className="note-description">
            <InspectionNoteDescription
              severity={props.note.severity as string}
              description={props.note.description}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <InspectionNoteValues values={props.note.values} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <InspectionImages note={props.note} images={props.note.images ?? []} />
        </div>
      </div>
    </>
  );
}

